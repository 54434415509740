import React from 'react'
import Content from '../Components/Content'
import Hero from '../Components/Hero'

const Home = () => {
    return (
        <>
            <Hero />
            <Content />
        </>
    )
}

export default Home
